import React from 'react'
import HeroSection from '../About/component/Herosection'
import LightProduct from './Component/LightProduct'
import initialProducts from '../../utilities/initialData'

export default function Products() {
  return (
    <div>
      <HeroSection />
      {initialProducts.map((product) => (
        <LightProduct
          key={product.id}
          name={product.name}
          category={product.category}
          description={product.description}
          id={product.id}
          image={product.image}
        />
      ))}
    </div>
  )
}

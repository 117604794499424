import React, { useState, useEffect } from "react";
import { ReactComponent as CarrotIcon } from "./icons/carrot.svg";
import { ReactComponent as CauliflowerIcon } from "./icons/cabbage.svg";
import { ReactComponent as RedOnionIcon } from "./icons/Red onion.svg";
import { ReactComponent as StrawberryIcon } from "./icons/icon-Strawberry.svg";
import { ReactComponent as BlueberryIcon } from "./icons/blueberry 2.svg";
import { ReactComponent as SweetPotatoIcon } from "./icons/Potato.svg";

// TypeScript interface for the items
interface FruitOrVegItem {
  title: string;
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
}

const FruitsAndVeggies: React.FC = () => {
  // List of fruits and vegetables with their titles and corresponding SVG icons
  const items: FruitOrVegItem[] = [
    { title: "Carrot", Icon: CarrotIcon },
    { title: "Cauliflower", Icon: CauliflowerIcon },
    { title: "Red Onion", Icon: RedOnionIcon },
    { title: "Strawberry", Icon: StrawberryIcon },
    { title: "Blueberry", Icon: BlueberryIcon },
    { title: "Sweet Potato", Icon: SweetPotatoIcon },
  ];

  // State for tracking the active item
  const [activeIndex, setActiveIndex] = useState(0);

  // Cycle through the items every 1 second
  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % items.length); // Loop back to first item after last
    }, 1000);

    return () => clearInterval(interval); // Cleanup on unmount
  }, [items.length]);

  return (
    <div className="h-auto bg-[#334b35] px-4 py-8">
      {/* Title Section */}
      <div className="text-center text-white mb-8">
        <h1 className="text-lg font-semibold  uppercase leading-[30px]">
          Foods And Vegetables
        </h1>
        <h2 className="text-3xl font-light font-primary">
          Quality Fruits & Vegetables
        </h2>
      </div>

      {/* Grid Container */}
      <div className="flex flex-row gap-8 justify-center items-center flex-wrap">
        {items.map((item, index) => (
          <div
            key={index}
            className={`rounded-[15px] p-6 backdrop-blur-[50px] flex flex-col items-center text-center transition duration-500 ease-in-out transform ${
              index === activeIndex
                ? "bg-[#f7c35f] scale-105" // Active state with color and scale
                : "bg-[#d9d9d9]/10"
            } hover:bg-[#4b7551] hover:scale-110`} // Hover color and scaling
          >
            {/* Icon */}
            <div
              className={`w-[40px] h-[40px] mb-4 transition duration-500 ease-in-out fill-current ${
                index === activeIndex ? "text-primary" : "text-white"
              } hover:text-[#a0ff7f]`} // Hover icon color
            >
              <item.Icon className="w-full h-full" />
            </div>

            {/* Title */}
            <div
              className={`text-xs font-thin font-primary transition duration-500 ease-in-out ${
                index === activeIndex ? "text-primary" : "text-white"
              } hover:text-[#a0ff7f]`} // Hover title color
            >
              {item.title}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FruitsAndVeggies;

// src/utilities/initialData.ts

import { Dates, Mango, Onion, pomegranates, potato, strawberries } from "../assets";

interface FarmSection {
  id: number;
  name: string;
  description: string;
  image: string;
}

interface TeamMember {
  id: number;
  name: string;
  position: string;
  bio: string;
  image: string;
}

export const farmSections: FarmSection[] = [
  {
    id: 1,
    name: 'Organic Vegetables',
    description: 'Fresh and organic vegetables grown with love.',
    image: '/assets/images/organic-vegetables.jpg',
  },
  // Add more sections
];

export const teamMembers: TeamMember[] = [
  {
    id: 1,
    name: 'Ahmed Eldesouky',
    position: 'Founder',
    bio: 'Ahmed has over 20 years of experience in organic farming.',
    image: '/assets/images/ahmed.jpg',
  },
  // Add more team members
];


// Define the initial data
export const initialProducts = [
  {
    name: 'Mango',
    category: 'Fruits',
    description: 'Support the Aleph Zero core team through community and marketing initiatives while accumulating rewards.',
    id: '1',
    image: Mango,
  },
  {
    name: 'Pomegranate',
    category: 'Fruits',
    description: 'A rich source of antioxidants and vitamins, perfect for a healthy diet.',
    id: '2',
    image: pomegranates,
  },
  {
    name: 'Frozen Strawberry',
    category: 'Fruits',
    description: 'Deliciously sweet strawberries preserved at peak freshness.',
    id: '3',
    image: strawberries,
  },
  {
    name: 'Sweet Potatoes',
    category: 'Vegetables',
    description: 'Nutritious and versatile, sweet potatoes are a great addition to any meal.',
    id: '4',
    image: potato,
  },
  {
    name: 'Onion',
    category: 'Vegetables',
    description: 'A kitchen staple that adds flavor and depth to any dish.',
    id: '5',
    image: Onion,
  },
  {
    name: 'Dates',
    category: 'Fruits',
    description: 'Naturally sweet and packed with nutrients, dates are a healthy snack.',
    id: '6',
    image: Dates,
  },
];

export default initialProducts;

import React from 'react';
import { Grid, TextField, Button, Typography, Box } from '@mui/material';

const Contact: React.FC = () => {
  return (
    <Box sx={{ padding: { xs: '20px', md: '100px 60px' }, backgroundColor: '#29372A', color: '#fff' }}>
      <Grid container spacing={4} className='lg:min-h-[50vh]'>
        {/* Contact Information */}
        <Grid item xs={12} md={6}>
          <Typography variant="h6" component="div" sx={{ fontFamily: 'Poppins', textTransform: 'uppercase' }}>
            Contact Now
          </Typography>
          <Typography variant="h3" component="div" sx={{ fontFamily: 'Luckiest Guy', margin: '10px 0' }}>
            GET IN TOUCH NOW
          </Typography>
          <Typography variant="body1" component="div" sx={{ fontFamily: 'Lora', marginBottom: '30px' }}>
            Lorem ipsum dolor sit amet, adipiscing elit. In hac habitasse platea dictumst. Duis porta, quam ut finibus ultrices.
          </Typography>

          <Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', fontFamily: 'Lora' }}>
            Phone
          </Typography>
          <Typography variant="body2" component="div" sx={{ fontFamily: 'Livvic', marginBottom: '20px' }}>
            +880123456789
            <br />
            +880987654321
          </Typography>

          <Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', fontFamily: 'Lora' }}>
            Email
          </Typography>
          <Typography variant="body2" component="div" sx={{ fontFamily: 'Livvic', marginBottom: '20px' }}>
            needhelp@company.com
          </Typography>

          <Typography variant="subtitle1" component="div" sx={{ textTransform: 'uppercase', fontFamily: 'Lora' }}>
            Address
          </Typography>
          <Typography variant="body2" component="div" sx={{ fontFamily: 'Livvic' }}>
            Road No. 8, Niketan, Dhaka, Bangladesh
          </Typography>
        </Grid>

        {/* Form Section */}
        <Grid item xs={12} md={6}>
          <TextField
            fullWidth
            label="Your Name"
            variant="outlined"
            sx={{
              marginBottom: '20px',
              '& .MuiInputBase-root': { backgroundColor: '#263C28', color: '#fff' },
              '& .MuiOutlinedInput-root': { borderRadius: '10px' }
            }}
          />
          <TextField
            fullWidth
            label="Phone Number"
            variant="outlined"
            sx={{
              marginBottom: '20px',
              '& .MuiInputBase-root': { backgroundColor: '#263C28', color: '#fff' },
              '& .MuiOutlinedInput-root': { borderRadius: '10px' }
            }}
          />
          <TextField
            fullWidth
            label="Your Email"
            variant="outlined"
            sx={{
              marginBottom: '20px',
              '& .MuiInputBase-root': { backgroundColor: '#263C28', color: '#fff' },
              '& .MuiOutlinedInput-root': { borderRadius: '10px' }
            }}
          />
          <TextField
            fullWidth
            label="Your Message"
            variant="outlined"
            multiline
            rows={6}
            sx={{
              marginBottom: '20px',
              '& .MuiInputBase-root': { backgroundColor: '#263C28', color: '#fff' },
              '& .MuiOutlinedInput-root': { borderRadius: '10px' }
            }}
          />
          <Button
            fullWidth
            variant="contained"
            sx={{
              backgroundColor: '#f7c35f',
              color: '#29372A',
              borderRadius: '20px',
              padding: '15px',
              textTransform: 'uppercase',
              fontFamily: 'Livvic',
              '&:hover': {
                backgroundColor: '#f5b94d',
              },
            }}
          >
            Send Message
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Contact;

import React from "react";
import { treePaper } from "../assets";

const Footer = () => {
  return (
    <footer className="w-full bg-[#263c28] py-10 px-6 text-white">
      <div className="container mx-auto flex flex-wrap justify-between items-start border-b-[1px] pb-2">
        {/* Left Section */}
        <div className="flex flex-col gap-8 w-2/3">
          {/* Header and Logo */}
          <div className="flex  items-center">
            <div className="text-[#f7c35f] text-3xl w-fit font-normal font-primary">
              Eldesouky farm
            </div>
            <img
              className="w-[40px] h-[30px] sm:w-[60px] sm:h-[50px] animate-pulse"
              src={treePaper}
              alt="Farm Logo"
            />
          </div>
          {/* Description */}
          <div className="text-white text-base font-semibold leading-6 w-full">
            From our farm to your table, with love and care. 🌱🍅🥕
          </div>
          {/* Social Icons */}
          <div className="flex gap-4">
            {/* Add social icons here */}
          </div>
        </div>

        {/* Divider */}
        <div className="w-full border-t border-white/20 my-6"></div>

        {/* Center Section */}
        <div className="flex flex-col gap-8 w-1/3">
          {/* Useful Links */}
          <div>
            <div className="text-xl font-semibold font-['Livvic'] mb-4">
              Useful Links
            </div>
            <ul className="space-y-2 text-sm font-semibold font-['Lora']">
              <li>Fresh Vegetables</li>
              <li>Fresh Fruits</li>
              <li>Frozen</li>
              <li>About Us</li>
              <li>Contact Us</li>
            </ul>
          </div>
        </div>

        {/* Right Section */}
        <div className="flex flex-col gap-6 w-1/3">
          {/* Newsletter */}
          <div>
            <div className="text-xl font-semibold font-['Livvic'] mb-4">
              Newsletter
            </div>
            <div className="text-base font-semibold font-['Lora'] leading-6">
              Subscribe to our Newsletter and receive updates via email.
            </div>
          </div>
          {/* Subscription Form */}
          <div className="relative">
            <input
              type="email"
              placeholder="Enter your mail here..."
              className="w-full h-14 bg-[#263c28] text-white placeholder-white/50 rounded-full px-6 py-2"
            />
            <button className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-[#f7c35f] text-[#1a1a1a] rounded-full px-4 py-2 font-medium font-['Livvic'] uppercase">
              Go
            </button>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center text-xs rounded-2xl bg-[#f7c35f] px-4 py-2 font-thin font-primary opacity-35 mt-4">
        <a
          href="https://www.codeocean.tech/"
          target="_blank"
          rel="noopener noreferrer"
          className="hover:underline"
        >
          Copyright ©codeocean.
        </a>
        <div className="flex gap-4">
          <div>Terms & Conditions</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
